@use "../../../styles/variables.module" as *;

.container {
  .popup {
    position: absolute;
    bottom: calc(100% + 10px);
    background: white;
    box-shadow: $box-shadow;
    border: 1px solid rgba(0,0,0,.2);
  }
}

.arrow {
  content: "";
  background: white;
  width: 20px;
  height: 20px;
  bottom: -10px;
  position: absolute;
  right: calc(50% - 10px);
  transform: rotate(45deg);
  z-index: 0;
  clip-path: polygon(0 20px, 20px 0, 20px 20px, 0 20px);
  border: 1px solid rgba(0,0,0,.2);

  &.bottom {
    left: calc(50% - 10px);
    bottom: -10px;
  }
  &.top {
    left: calc(50% - 10px);
    top: -10px;
  }
  &.left {
    left: -10px;
    bottom: calc(50% - 10px);
  }
  &.right {
    right: -10px;
    bottom: calc(50% - 10px);
  }
}
