@use "../../../../styles/variables.module";

.container {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.image {
    @media(max-width: variables.$break-point-sm) {
        height: 75vw;
        width: 100vw;
        aspect-ratio: 4/3;
    }
    @media(min-width: variables.$break-point-sm) {
        height: 100vh;
        width: 100vw;
    }
}

.cover {
    composes: image;
    background-size: cover;
    background-position: center center;
    z-index: -1;
    &WelcomePage {
        height: 100vh;

    }
}

.repeatX {
    composes: image;
    height: 100%;
    background-repeat: repeat-x;
}

.repeatY {
    composes: image;
    width: 100%;
    background-repeat: repeat-y;
}


