@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";

.container {
  @media(min-width: $break-point-sm) {
    @include mixins.maxHeightTransition(60vh);
    position: absolute;

  }
  @media(max-width: $break-point-sm) {
    @include mixins.maxHeightTransition(600vh);
    transition: max-height .5s ease, opacity .5s ease;
    width: 100%;
    left: 0;

  }
  bottom: $primary-nav-height;
  left: 130px;
  z-index: 1;

  &Hidden {
    display: none;
  }


  button {

    letter-spacing: .4rem;
    background: var(--secondaryNavBackground);
    color: var(--secondaryNavBackgroundTextColor);
    border: 1px solid var(--secondaryNavBackground);
    padding: 0.75rem 2rem;
    border-radius: 10px;
    transition: border-color .25s ease, padding .25s ease;
    @media(max-width: $break-point-sm) {
      width: 100%;
      border: 0 !important;
      border-radius: 0;
      letter-spacing: .2rem;
      font-weight: bold;
      font-size: 1.6rem;
    }
    @media(min-width: $break-point-sm) {
      box-shadow: 8px 8px 8px rgba(0, 0, 0, .6);
      &:hover {
          padding: .6rem 1.8rem;
          border: 1px solid var(--primaryNavBackgroundTextColor);
      }
    }

  }
}
.desktopDropdown {
  @media(max-width: $break-point-sm) {
    display: none;
  }
}
.mobileDropdown {
  @media(min-width: $break-point-sm) {
    display: none;
  }
  .breakContainer {
    @media(min-width: $break-point-sm) {
      display: none;
    }
    display: flex;
    justify-content: center;
    background-color: var(--secondaryNavBackground);
    hr {
      margin: 0;
      background-color: var(--secondaryNavBackground);
      border: 1px solid var(--secondaryNavBackgroundTextColor);
      width: 80%;
    }
  }
}
.buttonTextContainer {
  width: fit-content;
  text-align: center;
  display: inline-block;
  position: relative;
}
.faIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.stickyButton {
  position: sticky;
  top:0;
  z-index: 1;
  display: inline-block;
}
.categoryList{
  padding-bottom: 18px;
}
.dropdown {
  position: absolute;
  @media(max-width: $break-point-sm) {
    bottom: 0;
    width: 100%;
    position:relative;
    font-size: 1.6rem;
    background: var(--secondaryNavBackground);
    color: var(--secondaryNavBackgroundTextColor);
  }
  bottom: calc(100% + 10px);
  left: 0;
  background: white;
  transition: max-height .25s ease;
  @media(min-width: $break-point-sm) {
    overflow-y: scroll;
  }

  h2 {
    font-size: 15px;
    font-weight: 700;
    margin: 0;
    @media(max-width: $break-point-sm) {
      font-size: 20px;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    @media(max-width: $break-point-sm) {
      padding: 1rem 1rem 1rem 3rem;
    }
    padding: 1rem 1rem 1rem 2rem;

    li {
      font-size: 14px;
      @media(max-width: $break-point-sm) {
        font-size: 20px;
      }
      padding: .25rem;
    }

    a {
      color: $black;
      font-weight: 500;
      @media(max-width: $break-point-sm) {
        color: var(--secondaryNavBackgroundTextColor);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.arrow {
  position: absolute;
  bottom: 100% !important;
  transition: opacity 1s ease;
  opacity: 0;

  &Visible {
    opacity: 1;
  }
}
