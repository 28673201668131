@use '../../../styles/variables.module' as *;
@use "./NavigationSecondary.module";

$text-color: #fff;

.callToActionText {
    color: $call-to-action-color !important;
}

.playPauseButton {
    position: absolute;
    right: 10px;
    bottom: 12px;
    color: white;
    font-size: 2rem;
}

.container {
    position: relative;
    z-index: 0; /* make sure that the hero container text scrolls *behind* the main content */
    overflow: hidden;
    background: $black;
    @media (min-width: $break-point-lg) {
        margin-top: -$primary-nav-height;
        height: 100vh;
    }
    max-height: 100vh;
    @media (max-width: $break-point-sm) {
        border-top: 1px white solid;
        border-bottom: 1px white solid;
    }
    &TitleOrg {
        @media(min-width: $break-point-lg) {
            margin-bottom: -$navbar-container-height;
        }
    }

    &IsWelcomePage {
        margin-top: -$primary-nav-height;
        height: 100vh;
    }

    &FloatingBlocksTheme {
        margin-bottom: -$navbar-container-height;

        &:not(&.containerIsWelcomePage) {
            margin-top: -($primary-nav-height + $floating-blocks-header-margin);
        }
    }

    &:not(&.containerIsWelcomePage) {
        @media(max-width: $break-point-sm) {
            .logo, .title {
                display: none;
            }
        }
    }
}
.desktopDropDownContainer {
    @media(max-width: $break-point-sm) {
        display: none;
    }
}
.mobileDropDownContainer {
    @media(min-width: $break-point-sm) {
        display: none;
    }
}

.headerTextContainer {
    color: $text-color;
    text-shadow: 1px 1px 2px #333;
    font-size: 2rem;
    @media(max-width: $break-point-sm) {
        font-size: 1.5rem !important;
    }
}

.headerText {
    display: inline-block;
    font-size: 1.9rem;

    a {
        color: $text-color !important;
        text-decoration: underline;
    }

    &Editable {
        cursor: pointer;

        a {
            pointer-events: none;
        }
    }
}

.viewHomepageButton {
    margin: 0 auto;
    height: 3.5rem;
    color: $text-color;
    background: rgba(0, 0, 0, .6);
    border: 1px solid lightgray;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    font-style: italic;
    font-size: 1.4rem;

    &:hover, &:focus, &:visited {
        color: unset;
        border: 5px solid lightgray;
        text-align: center;
    }
}

.sourcesWidget {
    position: absolute;
    bottom: 5rem;
    right: 2rem;
}

.editHeroButton {
    left: 2rem;
    bottom: 18rem;
    position: absolute;
    background-color: var(--darkerBlocks);
    color: var(--darkerBlocksTextColor) !important;
    width: 60px;
    height: 60px;
    line-height: 80px;
    text-align: center;
    border-radius: 50%;
    font-size: 3rem !important;
    z-index: 1;
    span{
        position: absolute;
        top: -.65rem;
        left: 1.25rem;
    }

    @media(max-width: $break-point-sm) {
        top: 2rem;
    }

    &OnWelcomePage {
        bottom: 6rem;
    }
}

.titleText {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: inherit;
    vertical-align: top;
    text-shadow: 1px 1px 2px #333;
    font-size: 4rem;
    color: white;
    font-weight: 700;

    &Terrace {
        font-size: 5rem;
    }
    &Preview {
        font-size: 3rem;
        @media(max-width: $break-point-sm) {
            font-size: 1rem;
        }
    }


    @media(max-width: $break-point-md) {
        font-size: 2.5rem;
    }


}

.heroText {
    text-align: center;
    position: absolute;
    top: 15rem;
    width: 100%;
    z-index: 1;
    &Preview {
        top: 0;
    }
}

.heroElementContainer {
    &TitleOrg {
        @media(min-width: $break-point-lg) {
            bottom: $navbar-container-height;
        }
    }

    .heroElementUnslider {
        overflow: hidden;

        > div:first-of-type {
            .transitionEnter, .transitionExitActive, .transitionExitDone {
                opacity: 0;
            }
            .transitionEnterActive, .transitionEnterDone, .transitionExit {
                opacity: 1;
            }

            > div {
                display: inline-block;
                transition: opacity .5s ease;
            }
        }

        > div:last-of-type { // controls div
            background-color: transparent;
            bottom: 0;
            button:not(:first-of-type) {
                box-shadow: black 2px 2px 3px;
            }
            @media(min-width: $break-point-lg){
                bottom: 50px;
            }
            @media (max-width: $break-point-sm) {
                width: 100%;
                position: sticky;
                background-color: var(--secondaryNavBackground) !important;
                button {
                    width: 2rem;
                    height: 2rem;
                    margin: 0 4px 3px 6px;
                }
            }

            > button:first-of-type { // pause button
                // match video pause button styles
                left: unset;
                right: 10px;
                bottom: 12px;
                font-size: 2rem;
                @media(max-width: $break-point-sm) {
                    right: 0;
                }
            }
        }
    }
}

.divider {
    height: 10px;
    background-color: var(--secondaryNavBackground);
}
