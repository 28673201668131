@use "../../../../styles/variables.module" as *;

.desktopView {
  margin-bottom: 250px;
  display: flex;
  justify-content: center;
  position: relative;

  @media(max-width: $break-point-sm) {
    margin-bottom: 100px;
  }
}

.mobileView {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin-bottom: 5%;
}

.preview {
  position: relative;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1, p {
    margin-bottom: 10px;
    text-align: center;
  }
}

.laptopShell {
  width: 100%;
  position: absolute;
}

.phoneShell {
  height: 620px;
  width: 350px;
  position: absolute;
  z-index: 10;
  margin-top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.imagePreview {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  margin-top: -0.8%;

  .overlayWrapper {
    margin-top: 15%;
  }

  h2 {
    font-size: 3rem;
  }

  @media(max-width: $break-point-sm) {
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 0.7rem;
    }
    .overlayWrapper {
      margin-top: 5%;
    }
  }
}

.imagePreviewMobile {
  position: absolute;
  height: 100%;
  width: 100%;

  .overlayWrapper {
    margin-top: 15%;
  }

  h2 {
    font-size: 3rem;
  }
}

.logoContainerCropper {
  @media(max-width: $break-point-sm) {
    top: 2rem;
  }
}

.logoClassName {
  @media(max-width: $break-point-sm) {
    height: 4rem;
    img {
      max-width: 60%;
    }
  }
}

.displayViewHomepageButtonClassName {
  @media(max-width: $break-point-sm) {
    height: 1.5rem;
    font-size: 0.5rem;
    padding: 0.1rem 0.8rem;
    border-radius: 0.4rem;
  }
}

.previewBox {
  position: relative;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  color: black;
  width: 100%;
}

.mobilePreviewBox {
  position: relative;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  background-size: cover;
  color: black;
  width: 100%;
  padding-top: 20px;
  margin-inline: auto;
}
