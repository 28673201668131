@use '../../../styles/variables.module' as *;
@use 'navigation.module';
@use '../../../styles/zIndex.module' as zIndex;

.secondary {
  composes: navigation;
  position: sticky;
  top: $primary-nav-height;
  bottom: 0;
  left: 0;
  right: 0;
  composes: secondaryNavBackgroundColor;
  z-index: zIndex.$z-index-navigation-secondary;
  text-align: center;
  transition: opacity .5s ease;

  &FloatingBlocks {
    top: $primary-nav-height + 17px;
  }

  &SubOrg {
    opacity: 0;
    position: fixed;
    height: fit-content;
  }

  &Visible {
    opacity: 1;
  }

  @media(max-width: $break-point-lg) {
    display: none !important;
  }

  &EmptyFiller {
    height: 0;
      &Show {
        height: $navbar-container-height;
      }
  }
}