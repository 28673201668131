@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";
@use "../../blocks/TerracedGridTheme/StatementBlock/styles/StatementBlockCS.module";

.button {
  @include mixins.buttonColor($admin-blue-dark, white);
  padding: 1rem .5rem;
  border-radius: 10px;
  cursor: pointer;
}

.container {
  padding: 2rem 1rem 1rem 1rem;
}

.previews {
  > div {
    width: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 2rem;
    vertical-align: top;

    span {
      display: block;
    }
  }
}

.imageBox {
  position: relative;
  overflow: hidden;
  width: 100%;
}
