@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";
@use "../../../SchoolBlocks/EditBlockComponents/EditBlockInputs/RequiredInput.module";
@use "../../styles/TerraceDescriptionStyles.module";


$content-width-percent: 40%;

@mixin backgroundImageStyling() {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(2,0,36);
  background: linear-gradient(90deg,#000 $content-width-percent,hsla(0,100%,99%,0) 60%);
}

.messageTransitionContainer {
  @include mixins.translateTransition(translateX(-20%), translateX(0));
  @include mixins.opacityTransition(0, 1);
}

.disabledButton {
  > button {
    background-color: grey !important;
    border: 2px grey solid !important;
    cursor: not-allowed !important;
  }
}

.statementBlockContainer {
  text-align: left;
  display: flex;
  justify-content: space-between;
  column-gap: 2%;

  > * {
    &:nth-of-type(1) {
      flex-basis: 40%;
    }
    &:nth-of-type(2) {
      flex-basis: 30%;
    }
    &:nth-of-type(3) {
      flex-basis: 30%;
    }
  }

  @media(max-width: $break-point-md) {
    flex-wrap: wrap;

    > * {
      &:nth-of-type(1) {
        flex-basis: 100%;
      }
      &:nth-of-type(2) {
        flex-basis: 100%;
      }
      &:nth-of-type(3) {
        flex-basis: 100%;
      }
    }
  }
}

.contentContainer {
  overflow: auto;
  height: 290px;
  padding-right: 10px;
  @include TerraceDescriptionStyles.terraceDescription;
}

.editor {
  @include TerraceDescriptionStyles.terraceDescription;
}

.blockContainerBackgroundImage {
  background: var(--opaqueSecondaryNavBackgroundRGB);
  padding: 10px 0px 20px 0px;
}

.messageContainer {
  color: white;
  position: relative;
  margin-right: 10px;
  vertical-align: middle;
  overflow-wrap: break-word;
  transition: transform .25s ease, opacity .5s ease;
  transition-delay: .25s;
  transform: translateX(-20%);
  opacity: 0;

  // add separation between and push callout button to bottom
  display: flex;
  row-gap: 25px;
  flex-direction: column;
  justify-content: space-between;

  &NoButton {
    padding-bottom: 40px;
  }

  @media(min-width: $break-point-md) {
    max-width: 40%;
  }
  @media (max-width: $break-point-sm) {
    row-gap: 0px;
  }
}

.statementContainer {
  position: relative;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  color: black;
  width: 100%;
  height: 100%;
  min-height: 375px;

  header {
    padding-top: 2rem;
    color: white;
  }

  h2 {
    max-width: inherit;
  }
}

.statementContainer .statementEditorContainer {
  padding: 0 $terrace-outer-margin;
}

.statementEditorContainer {
  background-color: #E7E7E7;

  header {
    color: black;
  }
}

.editButtonContainer {
  font-size: 16px;
}
.editDropDown {
  > div {
    > button {
      color: white !important;
    }
  }
}

.statementGradient {
  position: relative;
  padding: 0 $terrace-outer-margin;
  @include backgroundImageStyling();

  @media(max-width: $break-point-md) {
    background: rgb(2,0,36);
  }
}

.buttonInputContainer{
  width: 100%;
  .buttonInputs {
  margin-bottom: 5%;
  width: 90%;

  > div > input {
    margin: 10px 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid grey;
    &:required {
      border-color: red;
    }
  }
  }
}

.statementButtonLabel {
  font-weight: bold;
  margin-right: 3%;

  @media (max-width: $break-point-sm) {
    color: black;
  }
}

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.linkButtonContainer {
  text-align: center;
  @media (min-width: $break-point-sm) {
    width: 90%;
    padding-bottom: 20px;
  }
  @media (max-width: $break-point-sm) {
    padding-top: 20px;
  }
}

.callToActionLink {
  @include TerraceDescriptionStyles.callToActionButton;
  background: var(--secondaryNavBackground);
  color: var(--secondaryNavBackgroundTextColor);
  border: 2px solid white;
  text-align: center;

  // handle hover transition
  margin: .15rem .25rem;
  transition: padding .3s, margin .3s;

  @media (max-width: $break-point-sm) {
    min-width: 90vw;
  }

  &:hover {
    padding: 0.6rem 1.5rem;
    margin: 0;
    color: var(--secondaryNavBackgroundTextColor);
  }
}

.statementButton {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}


.videoContainer {
  vertical-align: middle;
  transition: transform .25s ease, opacity .5s ease;
  transition-delay: .5s;
  transform: translateX(20%);
  opacity: 0;
  height: 100%;
}

.statementEditMediaContainer {
  font-size: 1.2rem;
  border-radius: 10px;
  height: 100%;
  @media (min-width: $break-point-md) {
    max-height: 450px;
  }
}

.statementBackgroundImage {
  width: 100% - $content-width-percent;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media(max-width: $break-point-md) {
    display: none;
  }
}

.statementMobileImage {
  width: 100vw;
  margin-left: -$terrace-outer-margin;
  padding-top: 20px;

  @media(min-width: $break-point-md) {
    display: none;
  }
}

.dropdown {
  background: white;
  margin: 10px 0;
  border-radius: 5px;

  @extend .input;

  > div:last-of-type {
    z-index: 2 !important;

    ul {
      max-height: 200px !important;
    }
  }

  &IsValid {
    @extend .inputValid;
  }
}

.currentLinkContainer {
  span {
    font-size: 1rem;
    word-break: break-all;
  }

  button span {
    color: $admin-blue-dark;
    font-size: 1.3rem;
  }
}

.blockTitleContainerClassName {
  margin-bottom: 0.75rem;
  div > div > div {
    padding: 0;
  }
}
