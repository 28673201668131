@use '../../../styles/variables.module';
// COMPOSURES:

.animatedTransition {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.navigation {
  margin: 0;
  border-radius: 0;
}
